<template>
  <div class="u-flex-shrink-0">
    <div
      v-if="recipes.length > 0"
      class="c-saved-recipes-summary  u-font-size-medium"
    >
      <div class="u-flex u-flex-direction-column u-mb--m">
        <strong class="u-flex-shrink-0 u-font-size-h4 u-mb--s">{{ title }}</strong>
        <span
          v-if="model.loginToSaveFavoriteRecipesText"
          class="u-flex-shrink-0 u-mb--s"
        >
          {{ model.loginToSaveFavoriteRecipesText }}
        </span>
        <ol class="c-saved-recipes-summary__list u-flex-shrink-0">
          <li
            v-for="(recipe, index) in recipes"
            :key="index"
            class="u-mb--xs"
          >
            <a
              :href="recipe.url"
              @click="trackRecipeClick(recipe.name)"
            >
              {{ recipe.name }}
            </a>
          </li>
        </ol>
      </div>
    </div>
    <div class="u-text-align--center">
      <Loader
        v-if="isLoadingSavedRecipes"
        size="large"
        color="medium-gray"
        class="u-mt--m u-flex u-ml--a u-mr--a"
      />
    </div>
  </div>
</template>

<script>

import recipeApi from '@/CVI/Recipe/lib/api'

import Loader from '@/CVI/WebCore/components/Loader.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Loader
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      recipes: [],
      isLoadingSavedRecipes: false
    }
  },
  computed: {
    title() {
      const { favoriteRecipeCountTextFormat } = this.model
      const { recipes } = this

      if (recipes.length && favoriteRecipeCountTextFormat) {
        return favoriteRecipeCountTextFormat.replace('{favoriteRecipeCount}', recipes.length)
      }

      return ''
    }
  },
  async mounted() {
    this.isLoadingSavedRecipes = true
    const recipesResponse = await recipeApi.favoriteRecipes.get(50)
    this.recipes = recipesResponse.data
    this.isLoadingSavedRecipes = false
  },
  methods: {
    trackRecipeClick(title) {
      tracker.trackSavedRecipesClick({
        event: 'savedRecipeClick',
        savedRecipeClickTitle: title
      })
    }
  }
}
</script>
